import { FC } from 'react';
import { useState } from 'react';
import Script from 'next/script';
import styles from './StickyBanner.module.scss';

const StickyBanner: FC = () => {
  const [isActive, setIsActive] = useState(true);

  const disableBannerHandle = () => {
    setIsActive(false);
  };

  return (
    <div className={`${styles.stickyBanner} ${!isActive ? `${styles.stickyBanner_disable}` : ``}`}>
      <div onClick={disableBannerHandle} className={styles.stickyBanner__btn}></div>
      <div className={styles.stickyBanner__wrap}>
        <div id="yandex_rtb_R-A-745622-7"></div>
        <Script strategy="lazyOnload">
          {`
          window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-745622-7',
          blockId: 'R-A-745622-7'})})
        `}
        </Script>
      </div>
    </div>
  );
};

export default StickyBanner;
