import { FC, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { getCurrentYear } from '@/helpers/helpers';
import { IGenresData } from '@/interfaces/genres.interface';
import { useActions } from '@/hooks/useActions';
import { useAuth } from '@/hooks/useAuth';
import Cookies from 'js-cookie';
import { getTruncatedTxt } from '@/helpers/helpers';
import { MENU_LINKS } from '@/helpers/variables';
import styles from './Menu.module.scss';

interface IMenuProps {
  isMobileMenu: boolean;
  setIsMobileMenu: (isMobileMenu: boolean) => void;
  menuGenres: IGenresData[];
}

const Menu: FC<IMenuProps> = ({ isMobileMenu, setIsMobileMenu, menuGenres }) => {
  const router = useRouter();
  const { logout } = useActions();

  const [isSuperGenres, setIsSuperGenres] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [isClient, setIsClient] = useState(false);

  const { user } = useAuth();
  const userName = user?.user_email || user;
  const token = Cookies.get('token');

  useEffect(() => {
    setIsClient(true);
  });

  const cabinetOutHandle = () => {
    logout();
  };

  const setMenuModeHandle = () => {
    setIsMobileMenu(!isMobileMenu);
  };

  const getSearchWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const searchByPressEnter = (event: React.KeyboardEvent<object>) => {
    if (String(event.code) == 'Enter') {
      router.push(`/search/${searchWord}/`);
    }
  };

  const searchWord = inputSearch.includes(' ') ? inputSearch.split(' ').join('+') : inputSearch;

  return (
    <>
      <div className={styles.menu__head}>
        <div className={styles.menu__logotype}>
          <Link prefetch={false} className={styles.menu__logotypeLink} href="/">
            <img width="150" height="63" className={styles.menu__logotypeImg} src="/logo.svg" alt="logo" />
          </Link>
        </div>

        <div className={styles.menu__searchForm}>
          <input value={inputSearch} onChange={getSearchWord} onKeyDown={searchByPressEnter} className={styles.menu__searchInput} name="query" type="text" placeholder="" />
          {!!inputSearch.length ? <Link prefetch={false} className={styles.menu__searchButton} href={`/search/${searchWord}`}></Link> : <span className={styles.menu__searchButton}></span>}
        </div>

        <div className={styles.menu__mobBtn} onClick={setMenuModeHandle}>
          <div className={styles.burger}>
            <span className={styles.burger__topper}></span>
            <span className={styles.burger__bottom}></span>
            <span className={styles.burger__footer}></span>
          </div>
        </div>
      </div>
      <div className={styles.menu__middle}>
        <ul className={styles.menuList}>
          {menuGenres && menuGenres.length && (
            <>
              {menuGenres.slice(0, 8).map((genre) => (
                <li key={genre.id} className={styles.menuList__item}>
                  <Link prefetch={false} href={`/${genre.alias}/`}>
                    {genre.title}
                  </Link>
                </li>
              ))}
              <li onClick={() => setIsSuperGenres(!isSuperGenres)} className={isSuperGenres ? `${styles.menuList__item} ${styles.menuList__item_toggle} ${styles.active}` : `${styles.menuList__item} ${styles.menuList__item_toggle}`}>
                Все жанры
              </li>
            </>
          )}

          <ul className={styles.menuList__genres}>
            {menuGenres &&
              menuGenres.length &&
              menuGenres.slice(8, 26).map((genre) => (
                <li key={genre.id} className={styles.menuList__item}>
                  <Link prefetch={false} href={`/${genre.alias}/`}>
                    {genre.title}
                  </Link>
                </li>
              ))}
          </ul>
        </ul>

        <ul className={`${styles.menuList} ${styles.menuList_main}`}>
          {MENU_LINKS.map((item, i) => (
            <li key={`${item.link}-${i}`} className={styles.menuList__item}>
              <Link prefetch={false} href={item.link}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>

        {isClient && userName && token ? (
          <div className={styles.menuList__cabinet}>
            <Link href="/user/settings" className={styles.menuList__user}>
              {getTruncatedTxt(userName, 9)}
            </Link>
            <Link href="/" className={styles.menuList__signOut} onClick={cabinetOutHandle}>
              Выйти
            </Link>
          </div>
        ) : (
          <Link prefetch={false} href="/login/" className={styles.menuList__signIn}>
            Войти
          </Link>
        )}

        <Link prefetch={false} href="/registration/" className={styles.menuList__register}>
          Зарегистрироваться
        </Link>
      </div>

      <div className={styles.menu__bottom}>
        <div className={styles.menu__emailInfo}>
          <div className={styles.menu__emailTitle}>По всем вопросам обращайтесь на:</div>
          <a target="_blank" href="mailto:info@litportal.ru" className={styles.menu__email}>
            info@litportal.ru
          </a>
        </div>
        <div className={styles.menu__years}>(©) 2003-{getCurrentYear()}.</div>
      </div>
    </>
  );
};

export default Menu;
