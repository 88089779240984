import { FC, PropsWithChildren, useState } from 'react';
import HeaderBanner from '../ui/banners/header-banner/HeaderBanner';
import Meta from '../seo/Meta';
import localFont from 'next/font/local';
import Menu from './menu/Menu';
import StickyBanner from '../ui/banners/sticky-banner/StickyBanner';
import FooterBanner from '../ui/banners/footer-banner/FooterBanner';
import { IMeta } from '../../interfaces/meta.interface';
import { IBookPage } from '@/interfaces/booksPage.interface';
import { IGenresData } from '@/interfaces/genres.interface';
import styles from './Layout.module.scss';

const fontArial = localFont({
  src: [
    {
      path: './../../fonts/arial.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: './../../fonts/arial-bold.woff',
      weight: '700',
      style: 'bold',
    },
  ],
});

interface ILayout extends IMeta {
  menuGenres: IGenresData[];
}

const Layout: FC<PropsWithChildren<ILayout>> = ({ children, title, description, pageHref, canonicalHref, robotsNoFollow, cover, menuGenres }) => {
  const [isMobileMenu, setIsMobileMenu] = useState(false);

  return (
    <Meta title={title} description={description} pageHref={pageHref} cover={cover} canonicalHref={canonicalHref} robotsNoFollow={robotsNoFollow}>
      <div className={styles.pageWrapper}>
        <HeaderBanner />

        <main className={styles.mainContainer} style={fontArial.style} menu-data={isMobileMenu ? 'mobile-menu' : 'desktop-menu'}>
          <div className={styles.mainContainer__menu}>
            <Menu isMobileMenu={isMobileMenu} setIsMobileMenu={setIsMobileMenu} menuGenres={menuGenres} />
          </div>

          <div className={styles.mainContainer__content}>
            <div className={styles.mainContainer__wrap}>{children}</div>

            <div className={styles.mainContainer__bottomBanner}>
              <FooterBanner />
            </div>
          </div>
        </main>
        <StickyBanner />
      </div>
    </Meta>
  );
};

export default Layout;
