import { FC, PropsWithChildren } from 'react';
import Head from 'next/head';
import { IMeta } from '../../interfaces/meta.interface';

const Meta: FC<PropsWithChildren<IMeta>> = ({ title, description, pageHref, canonicalHref, cover, robotsNoFollow, children }) => {
  return (
    <>
      <Head>
        {/* Добавить скрипты для прода */}
        <script async type="text/javascript" src="/static/google-tag-manager.js"></script>
        <script type="text/javascript" src="/static/yandex-context-cb.js"></script>
        <script async type="text/javascript" src="/static/ya-counter.js"></script>
        <noscript>
          <div>
            <img src="https://mc.yandex.ru/watch/45524814" style={{ position: 'absolute', left: '-9999px' }} alt="" />
          </div>
        </noscript>
        <script src="https://yandex.ru/ads/system/context.js" async />

        <title>{title}</title>

        {/* robots tag for test, remove after deploy] */}
        {/* <meta name="robots" content="noindex, nofollow" /> */}

        {/* <meta name="google-site-verification" content="" />
        <meta name="yandex-verification" content="" /> */}
        <meta name="description" content={description} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_SITE_URL}${pageHref}`} />
        {!!robotsNoFollow ? <meta name="robots" content="noindex, nofollow" /> : ``}
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_SITE_URL}${canonicalHref}`} />
        {cover ? (
          <>
            <meta property="og:type" content="books.book" />
            <meta property="og:image" content={cover}></meta>
          </>
        ) : (
          <>
            <meta property="og:image" content="/logo.svg"></meta>
          </>
        )}
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <link rel="icon" type="image/svg" href="/favicon/favicon.svg" />
      </Head>
      {/* Добавить для прода */}
      <noscript>
        <iframe loading="lazy" src="https://www.googletagmanager.com/ns.html?id=GTM-NDX9QG2" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
      </noscript>
      {children}
    </>
  );
};

export default Meta;
